import { Component, OnInit, HostBinding } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfigService } from '../services/app-config.service';
import { NgIf, NgFor } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-application-start',
  templateUrl: './application-start.component.html',
  styleUrls: ['./application-start.component.scss'],
  imports: [TranslateModule, NgIf, NgFor],
  standalone: true
})

export class ApplicationStartComponent implements OnInit {

  @HostBinding('class.route-card') card = true;
  @HostBinding('class.last-card') lastCard = true;

  introMessage: string;
  introMainMessage: string;

  constructor(
    private router: Router,
    private configService: AppConfigService
  ) { }

  ngOnInit(): void {
    const { job } = this.configService.config;

    this.introMessage = job.introMessage;
    this.introMainMessage = job.introMainMessage;
  }

  startJobApplication(): void {
    this.router.navigate(['quiz'], { queryParamsHandling: 'merge' });
  }
}
